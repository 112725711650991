import store from "@/store"
import Repository from "@/repositories/api.js"
const resource = "/admin/news"

export default {
  async getAll() {
    let response = await Repository.get(resource, {
      params: { region_id: store.state.auth.chosenRegion }
    })
    return response.data.news_items
  },
  async get(newsID) {
    let response = await Repository.get(`${resource}/${newsID}`)
    return response.data.news_item
  },
  async create(newsData) {
    let response = await Repository.post(`${resource}`, {
      region_id: store.state.auth.chosenRegion,
      news_item: newsData
    })
    return response.data
  },
  async update(newsID, newsData) {
    let response = await Repository.patch(`${resource}/${newsID}`, {
      news_item: newsData
    })
    return response.data
  },
  async destroy(newsID) {
    let response = await Repository.delete(`${resource}/${newsID}`)
    return response.data
  }
}
